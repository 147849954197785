var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      staticClass: "config-dialog-box",
      attrs: {
        title: "配置显示",
        width: "700px",
        visible: _vm.dialogVisible,
        "close-on-click-modal": false,
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
        close: _vm.handleClose,
      },
    },
    [
      _c(
        "el-form",
        {
          ref: "configForm",
          staticClass: "geomagnetic-pattern",
          attrs: { "label-width": "100px" },
        },
        _vm._l(_vm.formData, function (item, key) {
          return _c(
            "el-form-item",
            {
              key: key,
              staticClass: "geomagnetic-pattern-item",
              attrs: { "label-width": "20px" },
            },
            [
              _c("el-switch", {
                model: {
                  value: item.switchType,
                  callback: function ($$v) {
                    _vm.$set(item, "switchType", $$v)
                  },
                  expression: "item.switchType",
                },
              }),
              _c("div", { staticClass: "geomagnetic-pattern-box" }, [
                _c("div", { staticClass: "title" }, [
                  _vm._v(
                    _vm._s(_vm._f("formatTitle")(item.switchType, item.label))
                  ),
                ]),
                item.switchType
                  ? _c(
                      "div",
                      { staticClass: "config-item-box" },
                      _vm._l(item.switchList, function (sub, index) {
                        return _c(
                          "div",
                          {
                            key: key + "_" + index,
                            staticClass: "config-item",
                          },
                          [
                            _c(
                              "el-checkbox",
                              {
                                model: {
                                  value: sub.checked,
                                  callback: function ($$v) {
                                    _vm.$set(sub, "checked", $$v)
                                  },
                                  expression: "sub.checked",
                                },
                              },
                              [_vm._v(_vm._s(sub.label))]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  : _vm._e(),
              ]),
            ],
            1
          )
        }),
        1
      ),
      _c(
        "span",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer",
        },
        [
          _c("el-button", { on: { click: _vm.handleCancel } }, [
            _vm._v("取 消"),
          ]),
          _c(
            "el-button",
            { attrs: { type: "primary" }, on: { click: _vm.saveConfigFun } },
            [_vm._v("确 认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }